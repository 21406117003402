.ResultList {
    width: 100%;
    background-color: white;
    flex-direction: column;
    box-shadow: 0px 0px 8px #ddd;
    border-radius: 5px;
    margin-top: 0.1rem;
    margin-bottom: 1vh;
    max-height: 300px;
    vertical-align: middle;
}

.box{
    min-width: 60%;
    padding: 10px;
}

.ProductExample {
    width: 40%;
    min-width: 200px;
    display: flex;
    flex-direction: row;
    margin-top: 1vh;
    margin-bottom: 1vh;
}
  
.Logo {
    width: 4.8vw;
    margin-right: 1vw;
}

.websiteName {
    margin-left: 0.1rem;
    margin-right: 0.5rem;
    white-space: nowrap;
    display: flex;
    align-items: center;
    width: 200%;
    font-size: 20px;
}

.Rating {
    border-left: 2px solid #9b775d;
    padding-left: 10%;
    width: fit-content;
    font-size: 20px;
}

.RatingGraph {
    width: 100%;
    margin-top: 1vh;
    background-color: white;
    border-radius: 1rem;
    border: 2px solid #AD8263;
}

.fillIn {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #AD8263;
    border-radius: 1rem;
}

.Price {
    white-space: nowrap;
    font-size: 20px;

}

.Visit-container{
    justify-content: center;
    align-items: center;
    display: flex;
    float: right;
}

.Visit {
    border-radius: 5rem; 
    background-color: #AD8263; 
    height: 80%; 
    width: 80%;
    color: white; 
    text-align: center; 
    white-space: nowrap;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 20px;
}

.Visit:hover {
    color: #AD8263;
    border-style: solid;
    background-color: white;
    cursor: pointer;
}