.InputWrapper {
    background-color: white;
    width: 100%;
    border-radius: 10px;
    height: 2.5rem;
    box-shadow: 0px 0px 8px #ddd;
    display: flex;
    align-items: center;
}

input {
    background-color: transparent;
    border: none;
    height: 100%;
    font-size: 1.25rem;
    width: 100%;
    margin-left: 5px;
    color: #7b6353;
}

input:focus {
    outline: none;
}

#SearchIcon {
    padding-left: 1vw;
    color: #AD8263;
}

/* BUTTONS */
ul{
    list-style-type: none;
    display:flex;
    padding-left: 0;
    justify-content: center;
    /* margin-bottom: 0;  REBOOT.SCSS overlapping this and the <a> tag color */
}

li{
    border-style: solid;
    border-color: #AD8263;
    border-radius: 2rem;
    border-width: 3px;
}

.category-wrapper{
    float: left;
    padding: 20px 20px;
    font-size: 1.1em;
}

.category{
    float: left;
    padding: 20px 20px;
    font-size: 1.1em;
    text-decoration: none;
    border: none;
    background-color: transparent;
    color: #AD8263;
    font-weight: 500;
}

.category:hover{
    border-radius: 2rem;
    text-decoration: underline;
    text-underline-offset: 10px;
    text-decoration-thickness: 0.3em;
    cursor: pointer;
    color: #AD8263;
}